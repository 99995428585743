import * as React from 'react';
import { IconProps } from '../components/Icon';

export const CubeIcon = (props: IconProps['iconProps']) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M11.622 1.602a.75.75 0 0 1 .756 0l9 5.25a.75.75 0 0 1 .372.648v9a.75.75 0 0 1-.372.648l-9 5.25a.75.75 0 0 1-.756 0l-9-5.25a.75.75 0 0 1-.372-.648v-9a.75.75 0 0 1 .372-.648zM3.75 8.806l7.5 4.375v7.263l-7.5-4.375zm9 11.638 7.5-4.375V8.806l-7.5 4.375zM12 11.882 19.512 7.5 12 3.118 4.488 7.5z'
      clipRule='evenodd'
    />
  </svg>
);
