'use client';

import { useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { IMenuItem, Menu, Modal } from '#/packages/ui';
import { useContentItemsActionsContext } from './ContentItemActionsProvider';
import { useContentItems } from '../hooks/useContentItems';
import { useUpdateContentItemsChildren } from '../queries/useUpdateContentItemsChildren';
import { contentItemName, findParentPath, isFolderContentItem } from '../utils';

export const ContentItemMoveModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { actionAndContentItem } = useContentItemsActionsContext();
  const { id, name } = actionAndContentItem ?? {};
  const title = `Move ${name}`;
  const navigate = useRouter();
  const { contentItems, contentItemsMap, itemsParentMap } = useContentItems();

  const { mutate: updateChildrens } = useUpdateContentItemsChildren();

  const boardOptions = useMemo(
    () =>
      contentItems
        .filter((it) => isFolderContentItem(it))
        .sort((a, b) => (a?.updatedAt > b?.updatedAt ? -1 : 1))
        .map((it) => {
          const prevParentId = id ? itemsParentMap.get(id) : undefined;
          const contentItem = id ? contentItemsMap.get(id) : undefined;
          const parentPath = findParentPath(it.id, contentItems);

          if (
            (id && parentPath.includes(id)) ||
            it.id === prevParentId ||
            it.id === id ||
            contentItem?.children.includes(it.id)
          ) {
            return null;
          }

          return {
            id: it.id,
            label: <span className='max-w-full truncate'>{contentItemName(it)}</span>,
            onClick: () => {
              if (!prevParentId || !id) {
                return;
              }

              const prevParent = contentItemsMap.get(prevParentId);
              const newParent = contentItemsMap.get(it.id);

              const prevParentChildren = prevParent?.children.filter((childId) => childId !== id);
              const newParentChildren = [...(newParent?.children || []), id];

              if (!prevParentChildren || !newParentChildren) {
                return;
              }

              updateChildrens([
                {
                  contentItemId: prevParentId,
                  children: prevParentChildren
                },
                { contentItemId: it.id, children: newParentChildren }
              ]);

              navigate.push(`/${isFolderContentItem(it) ? 'board' : 'report'}/${it.id}`);

              onClose();
            }
          };
        })
        .filter(Boolean),
    [contentItems, id, itemsParentMap, contentItemsMap, updateChildrens, onClose]
  );

  return (
    <Modal size={'sm'} title={title} onClose={onClose} show>
      <Menu
        size={'lg'}
        className={'w-full flex flex-col sm:max-h-[400px] overflow-y-auto rounded-lg pr-2'}
        sectionClassName={
          'divide-y divide-black/5 first:border-none border-y border-y-black/5 !p-0 !m-0'
        }
        items={boardOptions as IMenuItem[]}
      />
    </Modal>
  );
};
