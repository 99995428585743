import { useAnalytics } from '#/packages/analytics';
import { PAYWALL_DATA } from '#/packages/subscription/constants/subscription-info';
import { useSubscription } from '#/packages/subscription/hooks/useSubscription';
import { PAYWALL_TYPE, SubscriberStatus } from '#/packages/subscription/types/Checkout';
import { Image, Modal, ModalCloseButton } from '#/packages/ui';
import { CubeIcon } from '#/packages/ui/src/icons/CubeIcon';
import { ReportIcon } from '#/packages/ui/src/icons/ReportIcon';
import { SignalIcon } from '#/packages/ui/src/icons/SignalIcon';
import waves2Svg from '#/src/assets/illustrations/waves2.svg';
import { NavButton } from '#/src/components/NavButton';
import { PaywallMobileImage } from './PaywallMobileImage';

const Loader = () => {
  return (
    <div className='absolute z-[9999999] w-full h-full bg-secondary-100'>
      <div
        className={`absolute inset-0 max-sm:hidden bg-secondary-300 animate-pulse`}
        style={{
          WebkitMaskImage: `url(${waves2Svg.src})`,
          maskImage: `url(${waves2Svg.src})`,
          WebkitMaskSize: 'cover',
          maskSize: 'cover',
          WebkitMaskPosition: 'center',
          maskPosition: 'center',
          WebkitMaskRepeat: 'repeat-y',
          maskRepeat: 'repeat-y'
        }}
      />
    </div>
  );
};

export const ContentItemLimitModalV2: React.FC<{
  onClose: () => void;
  paywallType?: PAYWALL_TYPE;
}> = ({ onClose, paywallType }) => {
  const analytics = useAnalytics();
  const { subscription } = useSubscription();

  return (
    <Modal
      size={'5xl'}
      show={true}
      onClose={onClose}
      overflowHidden={false}
      dialogClassName={'overflow-y-auto bg-secondary-100 relative'}
      noPadding
      withClose={false}
    >
      <ModalCloseButton
        onPress={onClose}
        className={
          'absolute top-6 right-6 z-10 text-neutral-10 hover:bg-white/10 bg-white/0 transition-all rounded-sm p-1'
        }
      />

      <div className='flex flex-col md:flex-row rounded-lg overflow-hidden'>
        <div className='w-full md:hidden'>
          <PaywallMobileImage className='w-full h-full min-h-36' />
        </div>

        <div className='md:p-10 p-6 w-full'>
          <h1 className='text-2xl font-medium text-gray-900'>Try Superads Professional for free</h1>
          <p className='mt-8 max-md:hidden'>
            {(paywallType && PAYWALL_DATA?.[paywallType]?.subtitle) ||
              `Unlock all Superads features and supercharge your creative and marketing teams${subscription?.status === SubscriberStatus.CANCELLED ? '.' : ', free for 30 days.'}`}
          </p>

          <div className='flex flex-col gap-4 mt-6 mb-10'>
            <div className='flex gap-3'>
              <ReportIcon className='min-w-6 min-h-6 w-6 h-6' />
              <span>
                Create <b>unlimited reports and boards</b>
              </span>
            </div>

            <div className='flex gap-3'>
              <CubeIcon className='min-w-6 min-h-6 w-6 h-6' />
              <span>
                Get access to <b>AI-analyzed dimensions</b> like value propositions, emotional
                triggers, hooks, and creative approaches
              </span>
            </div>

            <div className='flex gap-3'>
              <SignalIcon className='min-w-6 min-h-6 w-6 h-6' />
              <span>
                Gain a complete view of your marketing performance with{' '}
                <b>multi-channel and multi-account analysis</b>, all in one place
              </span>
            </div>
          </div>

          <NavButton
            size={'xl'}
            color={'primary'}
            wFull
            to={'/settings/upgrade'}
            className={'justify-center mb-2'}
            onClick={() => {
              analytics.track(
                `upgrade.paywall.${subscription?.status === SubscriberStatus.CANCELLED ? 'upgrade-now' : 'start-trial'}`
              );
            }}
          >
            {subscription?.status === SubscriberStatus.CANCELLED
              ? 'Upgrade now'
              : 'Start 30-day free trial'}
          </NavButton>

          <NavButton
            href={'https://calendly.com/andres-levinton/30min'}
            target={'_blank'}
            color={'secondary'}
            weight={'outline'}
            className={'justify-center mb-2'}
            size={'xl'}
            wFull
            onClick={() => {
              analytics.track('upgrade.paywall.book-a-demo');
            }}
          >
            Book a demo
          </NavButton>
        </div>

        <div className='md:min-w-[300px] lg:min-w-[500px] hidden md:block'>
          <Image
            fill
            Loader={Loader}
            className='w-full h-full min-h-[600px]'
            src={'/paywall.jpg'}
            alt={
              'Woman with floating UI elements showing ad campaign performance across LinkedIn, Meta, and TikTok'
            }
            unoptimized
          />
        </div>
      </div>
    </Modal>
  );
};
