import * as React from 'react';
import { IconProps } from '../components/Icon';

export const SignalIcon = (props: IconProps['iconProps']) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      fill='currentColor'
      d='M19.937 4.014a.9.9 0 0 0-1.273 1.272 9 9 0 0 1 0 12.728.9.9 0 1 0 1.273 1.273c4.217-4.218 4.217-11.056 0-15.273M5.936 5.286a.9.9 0 0 0-1.273-1.272C.446 8.23.446 15.069 4.663 19.287a.9.9 0 1 0 1.273-1.273 9 9 0 0 1 0-12.728'
    />
    <path
      fill='currentColor'
      d='M15.27 7.408a.9.9 0 0 1 1.273 0 6 6 0 0 1 0 8.485.9.9 0 1 1-1.273-1.273 4.2 4.2 0 0 0 0-5.94.9.9 0 0 1 0-1.272M9.33 7.408a.9.9 0 0 1 0 1.273 4.2 4.2 0 0 0 0 5.94.9.9 0 0 1-1.273 1.272 6 6 0 0 1 0-8.485.9.9 0 0 1 1.273 0M13.5 11.65a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0'
    />
  </svg>
);
