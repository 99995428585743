import { useMutation, useQueryClient } from '@tanstack/react-query';
import httpClient from '#/src/api/backendHttpClient';
import { useSessionWorkspaceId } from '#/src/context/session/hooks/useWorkspace';
import { setItem } from './optimistic-utils';
import { getContentItemQueryKey } from './useContentItemQuery';
import { ContentItem } from '../types/ContentItem';

export function useUpdateContentItemsChildren({ onSuccess }: { onSuccess?: () => void } = {}) {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId();

  const setChildren = ({
    contentItemId,
    children
  }: {
    contentItemId: string | null;
    children: string[];
  }) => setItem(contentItemId, { children }, { workspaceId, queryClient });

  return useMutation<
    ContentItem[],
    Error,
    {
      contentItemId: string | null;
      children: string[];
    }[],
    {
      items: {
        contentItemId: string | null;
        children: string[];
      }[];
    } | null
  >({
    mutationFn: (items) => {
      return Promise.all(
        items.map(({ contentItemId, children }) =>
          !contentItemId
            ? Promise.reject(new Error('No content item uuid'))
            : httpClient.post(`/content-item/${contentItemId}/children`, { children })
        )
      );
    },

    onMutate: async (items) => {
      const prev = items
        .map((item) =>
          item.contentItemId
            ? queryClient.getQueryData(getContentItemQueryKey(item.contentItemId))
            : null
        )
        .filter(Boolean) as {
        contentItemId: string | null;
        children: string[];
      }[];

      await Promise.all(items.map(setChildren));

      return prev ? { items: prev } : null;
    },

    onError: (_err, _newData, context) =>
      context?.items ? Promise.all(context?.items.map(setChildren)) : null,

    onSuccess: (contentItems) => {
      onSuccess?.();
      return Promise.all(
        contentItems?.map(
          (contentItem) =>
            contentItem?.id && setItem(contentItem.id, contentItem, { workspaceId, queryClient })
        )
      );
    }
  });
}
