import * as React from 'react';
import { IconProps } from '../components/Icon';

export const ReportIcon = (props: IconProps['iconProps']) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M3 3.75a.75.75 0 0 1 .75.75v8.847l4.756-4.161a.75.75 0 0 1 .944-.036l5.514 4.135 5.542-4.85a.75.75 0 0 1 .988 1.13l-6 5.25a.75.75 0 0 1-.944.035l-5.514-4.135L3.75 15.34v3.41H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75v-15A.75.75 0 0 1 3 3.75'
      clipRule='evenodd'
    />
  </svg>
);
